<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      :exportShow="true"
      @getChange="changeSelect"
      url="unCostExport"
      fileName="未收费列表"
      :exportData="searchForm"
    >
    </EForm>
    <div class="fs36 fwb clred">合计：{{ totalAmount }}元</div>
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      @changeSize="changeSize"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
      <div slot="isModify" slot-scope="scope">
        {{ scope.data.isModify == 1 ? '已修改' : '未修改' }}
      </div>
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <div>
            <EButton type="text" @click="dialogShow(scope.row.orderId)"> 明细 </EButton>
            <!--                        <el-button type="text" @click="print(scope.row.orderId)">-->
            <!--                            补打-->
            <!--                        </el-button>-->
          </div>
        </template>
      </el-table-column>
    </ETable>
    <EDialog :dialogVisible="dialogVisible" title="明细" @handleClose="cancelDialog" width="70%" @handleClick="cancelDialog">
      <ETable :tableTitle="tableTitle2" :tableData="tableData2" :needPagination="false"> </ETable>
    </EDialog>
    <div style="display: none">
      <!---->
      <div class="printDiv" v-for="(printInfo, index) in printList" :key="index" ref="print">
        <div class="title">
          <span class="w50px">
            {{ printInfo.areaName }}
          </span>
          <span class="w80px">
            {{ printInfo.tenantName }}
          </span>
          <span class="w180px">
            {{ printInfo.boothCode }}
          </span>
        </div>
        <ul class="voucherDiv1">
          <li v-for="(item, index) in printInfo.itemList">
            <div class="w-23">
              {{ item.takeReturnProjectName }}
            </div>
            <div class="w-15">
              {{ item.dealTypeCn }}
            </div>
            <div class="w-15">
              {{ item.payAmountCn }}
            </div>
            <div>
              {{ item.remark }}
            </div>
          </li>
        </ul>
        <div class="count">
          <span class="w60px">
            {{ printInfo.totalAmountUpper }}
          </span>
          <span class="w230px">
            {{ printInfo.totalAmount }}
          </span>
        </div>
        <div class="count">
          <span class="w35px">
            {{ printInfo.chargePersonName }}
          </span>
          <span class="w135px">
            {{ printInfo.createUserCn }}
          </span>
          <span class="w178px">
            {{ printInfo.payTime }}
          </span>
        </div>
      </div>
      <!--            <div class="printDiv" >-->
      <!--                <div class="title">-->
      <!--                    <span class="w50px">-->
      <!--                        {{printInfo.areaName}}-->
      <!--                    </span>-->
      <!--                    <span class="w80px">-->
      <!--                        {{printInfo.tenantName}}-->
      <!--                    </span>-->
      <!--                    <span class="w180px">-->
      <!--                           {{printInfo.boothCode}}-->
      <!--                    </span>-->
      <!--                </div>-->
      <!--                <ul class="voucherDiv1">-->
      <!--                    <li v-for="(item,index) in printInfo.itemList">-->
      <!--                        <div class="w-23">-->
      <!--                            {{item.takeReturnProjectName}}-->
      <!--                        </div>-->
      <!--                        <div class="w-15">-->
      <!--                            {{item.dealTypeCn}}-->
      <!--                        </div>-->
      <!--                        <div class="w-15">-->
      <!--                            {{item.payAmountCn}}-->
      <!--                        </div>-->
      <!--                        <div>-->
      <!--                            {{item.remark}}-->
      <!--                        </div>-->
      <!--                    </li>-->
      <!--                </ul>-->
      <!--                <div class="count">-->
      <!--                    <span class="w60px">-->
      <!--                        {{printInfo.totalAmountUpper}}-->
      <!--                    </span>-->
      <!--                    <span class="w230px">-->
      <!--                        {{printInfo.totalAmount}}-->
      <!--                    </span>-->
      <!--                </div>-->
      <!--                <div class="count">-->
      <!--                    <span class="w35px">-->
      <!--                         {{printInfo.chargePersonName}}-->
      <!--                    </span>-->
      <!--                    <span class="w135px">-->
      <!--                        {{printInfo.createUserCn}}-->
      <!--                    </span>-->
      <!--                    <span class="w178px">-->
      <!--                        {{printInfo.payTime}}-->
      <!--                    </span>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import { dateFormat } from '@/assets/js/common';
  import { mapGetters } from 'vuex';

  export default {
    name: 'chargedList',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '订单类型',
            prop: 'orderTypeCn',
          },
          // {
          //   label: '票据号',
          //   prop: 'orderNum',
          // },
          {
            label: '区域',
            prop: 'areaName',
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode',
          },
          {
            label: '商户名称',
            prop: 'tenantName',
          },
          {
            label: '负责人',
            prop: 'signPerson',
          },
          {
            label: '总金额为',
            prop: 'totalAmount',
          },
          {
            label: '收费人',
            prop: 'chargePersonName',
          },
          {
            label: '收费时间',
            prop: 'payTime',
          },

          // {
          //   label: '财务确认人',
          //   prop: 'confirmPersonName'
          // },
          // {
          //   label: '收/退',
          //   prop: 'orderTypeCn'
          // },
          {
            label: '转账金额',
            prop: 'transferAmount',
          },
          {
            label: '现金金额',
            prop: 'cashAmount',
          },
          {
            label: '财务确认时间',
            prop: 'confirmTime',
          },

          // {
          //   label: '是否修改',
          //   prop: 'isModify',
          //   type:'slot'
          // },
          // {
          //   label: '备注',
          //   prop: 'remark'
          // },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          // chargePersonName: null,
          // payTime: null,
          orderTypes:null,
          areaId: null,
          placeId: null,
          status:'0',
          current: 1,
          size: 10,
        },
        count: null,
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true,
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: [],
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: [],
          },
          {
            title: '订单类型',
            type: 'select',
            property: 'orderTypes',
            show: true,
            options: [
              {
                label:'全部',
                value:null
              },
              {
                label:'收费',
                value:'1,3,4,5'
              },
              {
                label:'退费',
                value:'6'
              },
              {
                label:'退租',
                value:'2'
              },
            ],
          },
          // {
          //   title: '收费人',
          //   type: 'text',
          //   property: 'chargePersonName',
          //   show: true,
          // },
          //
          // {
          //   title: '收费时间',
          //   type: 'rangePicker',
          //   property: 'payTime',
          //   show: true
          // },
        ],
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          area_code: vxRule(true, '', 'change', '推送区域不能为空'),
          title: [
            vxRule(true, '', 'blur', '标题不能为空'),
            vxRule(true, (val) => {
              return { result: val.length <= 20, errMsg: '最多可录入20字' };
            }),
          ],
          user_type: vxRule(true, '', 'change', '用户不能为空'),
          content: [
            vxRule(true, '', 'blur', '内容不能为空'),
            vxRule(true, (val) => {
              return { result: val.toString().length <= 75, errMsg: '最多可录入75字' };
            }),
          ],
          position_id: [vxRule(true, '', 'change', '跳转位置不能为空')],
          status: vxRule(true, '', 'change', '用户不能为空'),
        },
        options: [],
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '费用开始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
          {
            label: '周期',
            prop: 'periods',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
        ],
        tableData2: [],
        printList: [], //打印
        totalAmount: 0,
      };
    },
    watch: {},
    created() {
      this.getAreaLabel();

      let now = dateFormat.formatDate(new Date(), 'yyyy-MM-dd');
      // this.searchForm.payTime = [now, now]
      // this.searchForm.chargePersonName = this.userInfo.userName
      this.getData();
    },
    components: { ETable, EDialog, EButton, EForm },
    computed: {
      ...mapGetters(['userInfo']),
    },
    methods: {
      async print(id) {
        let res = await Http.getPrintData({ id });
        if (res.code == 200) {
          //外层
          //经营管理区 areaName
          //商户名称 tenantName
          //摊位号 boothCode
          //收款人 chargePersonName
          //录入人 createUserCn
          //合计大写 totalAmountUpper
          //合计 totalAmount
          //收款时间 payTime
          //itemList:
          //收费项 takeReturnProjectName
          //收退 dealTypeCn
          //金额 payAmount
          //备注 remark
          // this.getChange()
          this.printList = res.data;
          this.printList.forEach((item, index) => {
            this.$nextTick(() => {
              this.$print(this.$refs.print[index]);
            });
          });
          // this.payAndPrint()
        }
      },
      async initPrintData() {
        let arr = [];
        this.selectData.forEach((item) => {
          arr.push({
            cashAmount: item.cashAmount,
            orderId: item.orderId,
            transferAmount: item.transferAmount,
          });
        });
        let res = await Http.initPrintData(arr);
        if (res.code == 200) {
          //外层
          //经营管理区 areaName
          //商户名称 tenantName
          //摊位号 boothCode
          //收款人 chargePersonName
          //录入人 createUserCn
          //合计大写 totalAmountUpper
          //合计 totalAmount
          //收款时间 payTime
          //itemList:
          //收费项 takeReturnProjectName
          //收退 dealTypeCn
          //金额 payAmount
          //备注 remark
          // this.getChange()
          // let arr = res.data
          this.printList = res.data;
          console.log(111);
          console.log(this.$refs.print);
          this.printList.forEach((item, index) => {
            this.$nextTick(() => {
              this.$print(this.$refs.print[index]);
            });
          });
          // arr.forEach((item)=>{
          //   this.$nextTick(() => {
          //     this.printInfo=item
          //     this.$print(this.$refs.print)
          //   })
          //  })
        }
      },
      async dialogShow(id) {
        this.dialogVisible = true;

        let res = await Http.getListByOrderId({ id });
        if (res.code == 200) {
          this.tableData2 = res.data;
        }
      },
      changeSelect(prop) {
        if (prop == 'areaId') {
          this.getPlaceLabel();
        }
      },
      remove(row) {
        this.$messageBox
          .confirm('确认删除?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            this.getBasicInfoRemove(row.tenantBasicInformationId);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      async getBasicInfoRemove(ids) {
        let res = await Http.getBasicInfoRemove({ ids });
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getData();
        }
      },
      changeRadio() {
        this.getData();
        this.dialogForm.position_id = '';
      },
      cancelDialog() {
        this.dialogVisible = false;
        this.tableData2 = [];
      },
      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(page) {
        this.searchForm.current = page;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },
      async getData() {

        let res = await Http.unCostPage(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.page.records;
          this.count = res.data.page.total;
          this.totalAmount = res.data.totalAmount;
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId: this.searchForm.areaId,
        });
        if (res.code == 200) {
          this.formColumns[2].options = res.data;
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status: null,
        });
        if (res.code == 200) {
          this.formColumns[1].options = res.data;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.confirm-btn {
  text-align: right;
}

.printDiv {
  padding-top: 7.7px;
  margin-left: 66.6px;
  font-size: 12px;
  width: 616px;
  padding-bottom: 140px;

  .voucherDiv1 {
    font-size: 12px;
    margin-top: 38px;
    width: 100%;
    height: 205px;

    li {
      div {
        display: inline-block;
      }

      .w-23 {
        width: 23%;
      }

      .w-15 {
        width: 15%;
      }
    }
  }
}

.w50px {
  display: inline-block;
  box-sizing: content-box;
  width: 76px;
  padding-left: 76px;
}

.w80px {
  display: inline-block;
  width: 247px;
  padding-left: 57px;
}

.w180px {
  display: inline-block;
  width: 112.1px;
  padding-left: 45.6px;
}

.w60px {
  display: inline-block;
  width: 296.4px;
  padding-left: 87.4px;
}

.w230px {
  display: inline-block;
  width: 178.1px;
  padding-left: 51.8px;
}

.w35px {
  display: inline-block;
  width: 152px;
  padding-left: 53.2px;
  vertical-align: top;
}

.w135px {
  display: inline-block;
  width: 201.4px;
  padding-left: 30px;
  vertical-align: top;
}

.w178px {
  display: inline-block;
  width: 112.5px;
  padding-left: 45px;
}

.count {
  height: 25px;
  line-height: 25px;
}
</style>
